import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ["middle", "switcher"];

    static values = {
        active: Boolean,
        url: String
    }

    initialize() {

        let hash = window.location.hash;
        if (window.location.hash) {
            console.log(window.location.hash)
            var v = window.location.hash
            this.switcherTarget.value = v.substr(1);
            this.switch()
        }

    }

    switch() {

        const alldescriptions = this.middleTarget.querySelectorAll(".dteil");

        alldescriptions.forEach((entry) => {
            entry.classList.add("hidden")
        });

        if(this.switcherTarget.value) {

            history.replaceState({  }, '', this.urlValue+"#"+this.switcherTarget.value);

            var el = document.querySelector("#section-"+this.switcherTarget.value)
            if(el) {
                el.classList.remove("hidden")
            }

        } else {
            history.replaceState({  }, '', this.urlValue);
        }

    }


}