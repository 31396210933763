import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import AOS from "aos";

export default class extends Controller {

    static targets = ["description"];

    static values = {
        open: Boolean,
    }

    initialize() {



    }

    toggle(e) {
        const alldescriptions = this.descriptionTarget.querySelectorAll(".description");



        if(!alldescriptions[this.indexInParent(e.target)].classList.contains("hidden")) {
            alldescriptions[this.indexInParent(e.target)].classList.add("hidden")
        } else {

            alldescriptions.forEach((entry) => {
                entry.classList.add("hidden")
            });
            alldescriptions[this.indexInParent(e.target)].classList.remove("hidden")
        }

        setTimeout(() => {
            AOS.init();
        }, 50);

    }

    indexInParent(node) {
        var children = node.parentNode.childNodes;
        var num = 0;
        for (var i=0; i<children.length; i++) {
            if (children[i]==node) return num;
            if (children[i].nodeType==1) num++;
        }
        return -1;
    }

}