import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['footer'];

    static values = {

    }

    initialize() {

        this.resize()

    }

    resize() {

        //let footer_height = this.footerTarget.offsetHeight
        //document.querySelector("body").style.paddingBottom = footer_height+"px"

    }

}