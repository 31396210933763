import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    static targets = ["amount","amountdisplay","amountdisplaysingular","amountdisplayplural","date","datedisplay","hotelshort","hoteldisplay", "mobileversion", "desktopversion","mobileouter","buttonjb","buttons"];

    static values = {
        mobile: Boolean,
        active: Boolean
    }

    initialize() {
        this.updatebookingamount()

        this.mobileValue=false
        this.resize();


    }

    updatebookingamount() {
        let d = parseInt(this.amountTarget.innerHTML)
        if(d>1) {
            this.amountdisplaysingularTarget.classList.add("hidden")
            this.amountdisplaypluralTarget.classList.remove("hidden")
        } else {
            this.amountdisplaysingularTarget.classList.remove("hidden")
            this.amountdisplaypluralTarget.classList.add("hidden")
        }
        this.amountdisplayTarget.innerHTML = d;
    }

    updatebookingdate() {
        this.datedisplayTarget.innerHTML = this.dateTarget.value
    }

    updatebookinghotel() {
        this.hoteldisplayTarget.innerHTML = this.hotelshortTarget.getAttribute("data-short")
    }

    resize() {
        let window_width = window.innerWidth;
        let ismobile = false
        if(window_width<744) {
            ismobile=true
        }

        if(ismobile && this.mobileValue==false) {
            const d = this.desktopversionTarget.innerHTML
            this.desktopversionTarget.innerHTML = ""
            this.mobileversionTarget.innerHTML = d
        }
        if(!ismobile && this.mobileValue==true) {
            const d = this.mobileversionTarget.innerHTML
            this.mobileversionTarget.innerHTML = ""
            this.desktopversionTarget.innerHTML = d
        }
        this.mobileValue = ismobile

    }

    toggle() {
        this.activeValue = !this.activeValue

        if(this.activeValue) {

            document.body.style.top = `-${window.scrollY}px`
            document.body.style.position = 'fixed'
            document.body.style.overflowY = 'scroll'
            document.body.style.width = '100%'

            this.mobileouterTarget.classList.remove("hidden");
            setTimeout(() => {
                this.mobileouterTarget.classList.remove('translate-x-full');
            },50);

            this.buttonjbTarget.classList.add("hidden")
            this.buttonsTarget.classList.remove("hidden")

        }
        else {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.overflowY = ''
            document.body.style.width = ''

            window.scrollTo(0, parseInt(scrollY || '0') * -1);

            this.mobileouterTarget.classList.add('translate-x-full');

            this.mobileouterTarget.addEventListener('transitionend', () => {
                this.mobileouterTarget.classList.add("hidden");
            }, {once: true});

            this.buttonsTarget.classList.add("hidden")
            this.buttonjbTarget.classList.remove("hidden")
        }
    }

    availability(e) {

        const hotel = document.querySelector(".booking-bar").getAttribute("data-hotel")
        if(hotel=="") {
            alert(e.target.getAttribute("data-error"))
            return
        }

        const datestart = document.querySelector(".booking-bar").getAttribute("data-date-start")
        const dateend = document.querySelector(".booking-bar").getAttribute("data-date-end")

        const amount = document.querySelector(".booking-bar").getAttribute("data-amount")
        const languageid = document.querySelector(".booking-bar").getAttribute("data-languageid")

        const url = "https://reservations.travelclick.com/" + hotel + "?adults="+amount+"&datein="+datestart+"&dateout="+dateend+"&languageid="+languageid
        window.open(url)

    }

}