import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    static targets = ["display","hotellist"];

    static values = {

    }

    initialize() {
        if (this.hotellistTarget.querySelectorAll(".selected").length > 0)
        {
            this.preselect(this.hotellistTarget.querySelector(".selected"))
        }
        setTimeout(() => {
            document.querySelector(".booking-bar-inner").classList.remove("opacity-0")
        },50)
    }

    select(e) {
        this.displayTarget.innerHTML = e.target.getAttribute("data-label")
        this.displayTarget.setAttribute("data-short", e.target.getAttribute("data-short"))

        document.querySelector(".booking-bar").setAttribute("data-hotel", e.target.getAttribute("data-id"))

        const trigger_updatebooking_hotel = new CustomEvent("trigger-updatebookinghotel");
        window.dispatchEvent(trigger_updatebooking_hotel);
    }

    preselect(obj) {
        this.displayTarget.innerHTML = obj.getAttribute("data-label")
        this.displayTarget.setAttribute("data-short", obj.getAttribute("data-short"))

        document.querySelector(".booking-bar").setAttribute("data-hotel", obj.getAttribute("data-id"))

        const trigger_updatebooking_hotel = new CustomEvent("trigger-updatebookinghotel");
        window.dispatchEvent(trigger_updatebooking_hotel);
    }


}