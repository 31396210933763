import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import AOS from "aos";

export default class extends Controller {

    static targets = ["navigation","tabs"];

    static values = {
        open: Boolean,
    }

    initialize() {

        this.navigationTarget.querySelectorAll("div")[0].classList.add("active");

    }

    switch(e) {
        const allnaventries = this.navigationTarget.querySelectorAll("div");
        allnaventries.forEach((entry) => {
            entry.classList.remove("active")
        });
        e.target.classList.add("active")

        const tabs = this.tabsTarget.querySelectorAll(".tab")
        tabs.forEach((tab) => {
            tab.classList.add("hidden")
            if(this.indexInParent(tab) == this.indexInParent(e.target)) {
                tab.classList.remove("hidden")
            }
        });

        setTimeout(() => {
            AOS.init();
        }, 50);

    }

    indexInParent(node) {
        var children = node.parentNode.childNodes;
        var num = 0;
        for (var i=0; i<children.length; i++) {
            if (children[i]==node) return num;
            if (children[i].nodeType==1) num++;
        }
        return -1;
    }

}