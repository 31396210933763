import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import AOS from "aos";

export default class extends Controller {

    static targets = ["navorte","navkategorien","gridelements","empty"];

    static values = {
        open: Boolean,
        ort: Number,
        kategorie: Number
    }

    initialize() {

        this.navorteTarget.querySelectorAll("div")[0].classList.add("active");
        this.navkategorienTarget.querySelectorAll("div")[0].classList.add("active");
        this.ortValue = 0
        this.kategorieValue = 0

    }

    switchOrte(e) {
        const allnaventries = this.navorteTarget.querySelectorAll("div");
        allnaventries.forEach((entry) => {
            entry.classList.remove("active")
        });
        e.target.classList.add("active")
        this.ortValue = parseInt(e.target.getAttribute("data-id"))
        this.switchgrid()
    }

    switchKategorien(e) {
        const allnaventries = this.navkategorienTarget.querySelectorAll("div");
        allnaventries.forEach((entry) => {
            entry.classList.remove("active")
        });
        e.target.classList.add("active")
        this.kategorieValue = parseInt(e.target.getAttribute("data-id"))
        this.switchgrid()
    }

    switchgrid() {
        const jobs = this.gridelementsTarget.querySelectorAll(".teaser-job")
        let count = 0;
        jobs.forEach((job) => {
            job.classList.add("hidden")
            if(this.ortValue>0 && this.kategorieValue>0) {
                if(job.classList.contains("ort"+this.ortValue) && job.classList.contains("kategorie"+this.kategorieValue)) {
                    job.classList.remove("hidden")
                    count++;
                }
            } else if(this.ortValue>0 || this.kategorieValue>0) {
                if(this.ortValue>0 && job.classList.contains("ort"+this.ortValue)) {
                    job.classList.remove("hidden")
                    count++;
                }
                if(this.kategorieValue>0 && job.classList.contains("kategorie"+this.kategorieValue)) {
                    job.classList.remove("hidden")
                    count++;
                }
            } else if(this.ortValue==0 && this.kategorieValue==0) {
                job.classList.remove("hidden")
                count++;
            }
        });


        if(count==0) {
            this.emptyTarget.classList.remove("hidden")
            this.gridelementsTarget.classList.add("hidden")
        } else {
            this.emptyTarget.classList.add("hidden")
            this.gridelementsTarget.classList.remove("hidden")
        }

        setTimeout(() => {
            AOS.init();
        }, 50);
    }

    indexInParent(node) {
        var children = node.parentNode.childNodes;
        var num = 0;
        for (var i=0; i<children.length; i++) {
            if (children[i]==node) return num;
            if (children[i].nodeType==1) num++;
        }
        return -1;
    }

}