import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'
import AOS from "aos";

export default class extends Controller {

    static targets = ["scroller","zahlen","grid","indicator","indicatorinner"];

    static values = {
        active: Boolean,
        open: Boolean,
        current: Number,
        countmoves: Number,
        interval: Number
    }

    connect() {
        useIntersection(this, { eventPrefix: false })
    }

    initialize() {


        setTimeout(() => {
            this.resize()
        }, 50)


    }

    inViewPort() {

        if(this.activeValue) return;

        this.currentValue = 0

        this.intervalValue = setInterval(() => {
            this.currentValue = this.currentValue+1;
            if(this.currentValue>=this.countmovesValue) {
                this.currentValue = 0
            }
            const scrollerwidth = this.scrollerTarget.offsetWidth
            let gridelementwidth = (scrollerwidth/2)
            let window_width = window.innerWidth;

            let x = (-1*gridelementwidth*this.currentValue);

            if(window_width<520) {
                gridelementwidth = (scrollerwidth)
                x = (-1*gridelementwidth*this.currentValue)-(this.currentValue*48);
            }



            this.zahlenTarget.style.transform = "translateX("+x+"px)"


            this.indicatorinnerTarget.style.left = ((100/this.countmovesValue)*this.currentValue)+"%"

        }, 5000)

        this.activeValue = true;
    }

    outViewPort() {

        if(!this.activeValue) return;

        clearInterval(this.intervalValue)
        this.currentValue = 0
        this.zahlenTarget.style.transform = "translateX(0px)"
        this.indicatorinnerTarget.style.left = "0%"

        this.activeValue = false;

    }

    resize() {

        let scrollerwidth = this.scrollerTarget.offsetWidth
        let gridelementwidth = (scrollerwidth/2)-48
        let window_width = window.innerWidth;
        const gridelements = this.scrollerTarget.querySelectorAll(".grid-element")
        let countmoves = 0

        if(gridelements.length>4) {
            this.indicatorTarget.classList.remove("hidden")
            countmoves = Math.ceil((gridelements.length-4)/2) + 1
        } else {
            this.indicatorTarget.classList.add("hidden")
        }

        if(window_width<520) {
            gridelementwidth = (scrollerwidth)
            if(gridelements.length>2) {
                this.indicatorTarget.classList.remove("hidden")
                countmoves = Math.ceil((gridelements.length-2)/2) + 1
            } else {
                this.indicatorTarget.classList.add("hidden")
            }
        }

        this.countmovesValue = countmoves


        gridelements.forEach((ge) => {

            ge.style.width = gridelementwidth + "px"

        });

        this.scrollerTarget.style.height = this.gridTarget.offsetHeight + "px"

        this.indicatorinnerTarget.style.width = (100/countmoves) + "%"


        setTimeout(() => {
            AOS.init();
        }, 50);
    }



    indexInParent(node) {
        var children = node.parentNode.childNodes;
        var num = 0;
        for (var i=0; i<children.length; i++) {
            if (children[i]==node) return num;
            if (children[i].nodeType==1) num++;
        }
        return -1;
    }

}