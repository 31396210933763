import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ["accordion","iconclosed","iconopen"];

    static values = {
        open: Boolean,
    }

    initialize() {

        this.openValue = false;

    }

    toggle() {
        this.openValue = !this.openValue
        this.accordionTarget.classList.toggle("open")

        if(this.openValue) {
            this.iconclosedTarget.classList.add("hidden")
            this.iconopenTarget.classList.remove("hidden")
        } else {
            this.iconclosedTarget.classList.remove("hidden")
            this.iconopenTarget.classList.add("hidden")
        }

    }



}