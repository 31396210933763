import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import AOS from "aos";

export default class extends Controller {

    static targets = ["menu","back","mobile","mobileinner","desktop","wortmarke","outer","menutoggle"];

    static values = {
        active: Boolean,
        timeoutnav: Number,
        prescroll: Number
    }

    initialize() {

        this.mobileinnerTarget.innerHTML = this.desktopTarget.innerHTML
        this.activeValue = false
    }

    toggle() {
        this.activeValue = !this.activeValue

        if(this.activeValue) {
            this.backTarget.classList.remove("hidden");
            this.menuTarget.classList.add("hidden");

            document.body.style.top = `-${window.scrollY}px`
            document.body.style.position = 'fixed'
            document.body.style.overflowY = 'scroll'
            document.body.style.width = '100%'

            this.mobileTarget.classList.remove("hidden");
            setTimeout(() => {
                this.mobileTarget.classList.remove('translate-x-full');
                this.wortmarkeTarget.classList.add("!text-white");
                this.wortmarkeTarget.classList.add("z-[110]");
                this.menutoggleTarget.classList.add("z-[110]");
            },50);


        }
        else {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.overflowY = ''
            document.body.style.width = ''

            window.scrollTo(0, parseInt(scrollY || '0') * -1);

            this.backTarget.classList.add("hidden");
            this.menuTarget.classList.remove("hidden");

            this.mobileTarget.classList.add('translate-x-full');
            this.wortmarkeTarget.classList.remove("!text-white");
            this.wortmarkeTarget.classList.remove("z-[110]");
            this.menutoggleTarget.classList.remove("z-[110]");
            this.mobileTarget.addEventListener('transitionend', () => {
                this.mobileTarget.classList.add("hidden");
            }, {once: true});
        }
    }

    scroll() {
        let scroll = window.scrollY;
        let window_height = window.innerHeight;

        if(scroll>window_height || this.activeValue) {
            this.outerTarget.classList.add("navfixed")
        } else {
            this.outerTarget.classList.remove("navfixed")
        }


        clearTimeout(this.timeoutnavValue)

        if(scroll<this.prescrollValue) {
            this.timeoutnavValue = setTimeout(() => {
                this.outerTarget.classList.add("show")
            }, 50)
        } else {
            this.outerTarget.classList.remove("show")
        }

        this.prescrollValue = scroll


    }

}