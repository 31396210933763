import { Controller } from "@hotwired/stimulus"

import { easepick } from '@easepick/bundle';
import { RangePlugin } from '@easepick/range-plugin';
import { LockPlugin } from '@easepick/lock-plugin';
import '@easepick/bundle/dist/index.css'

export default class extends Controller {

    static targets = ["picker"];

    static values = {
        lang: String
    }

    initialize() {

        let lang = this.langValue

        const picker = new easepick.create({
            element: this.pickerTarget,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            lang: lang,
            format: "DD MMM YYYY",
            inline: true,
            RangePlugin: {
                locale: {
                    one: "Tag",
                    other: "Tage"
                }
            },
            LockPlugin: {
                minDate: "2023-12-14T23:00:00.000Z",
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                RangePlugin,
                LockPlugin
            ],
            setup(picker) {
                picker.on('select', (e) => {
                    const { view, date, target } = e.detail;

                    document.querySelector(".booking-bar").setAttribute("data-date-start", e.detail.start.format("MM/DD/YYYY"))
                    document.querySelector(".booking-bar").setAttribute("data-date-end", e.detail.end.format("MM/DD/YYYY"))

                    const trigger_updatebooking_date = new CustomEvent("trigger-updatebookingdate");
                    window.dispatchEvent(trigger_updatebooking_date);
                });
            },
        })

    }



}