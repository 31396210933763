import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import AOS from "aos";

export default class extends Controller {

    static targets = ["scroller","navigation"];

    static values = {
        open: Boolean,
    }

    initialize() {

        this.navigationTarget.querySelectorAll(".nav-item")[0].classList.add("active")
        const scrollers = this.scrollerTarget.querySelectorAll(".scroller")
        scrollers[0].classList.remove("opacity-0")

        setTimeout(() => {
            this.resize()
        }, 50)




    }

    resize() {
        let image_height
        const scrollers = this.scrollerTarget.querySelectorAll(".scroller")
        scrollers.forEach((scroller) => {

            const images = scroller.querySelectorAll("img")
            scroller.querySelector(".scroller-inner").style.width = (images.length * images[0].offsetWidth) + "px"
            image_height = images[0].offsetHeight

        });

        this.scrollerTarget.style.height = image_height + "px"

        setTimeout(() => {
            AOS.init();
        }, 50);
    }

    switch(e) {
        const nindex = this.indexInParent(e.target);
        const scrollers = this.scrollerTarget.querySelectorAll(".scroller")
        const navitems = this.navigationTarget.querySelectorAll(".nav-item")
        scrollers.forEach((scroller) => {
            const index = this.indexInParent(scroller);
            if(nindex == index) {
                scroller.classList.remove("opacity-0")
                navitems[index].classList.add("active")
            } else {
                scroller.classList.add("opacity-0")
                navitems[index].classList.remove("active")
            }
        });
        this.resize()
    }

    indexInParent(node) {
        var children = node.parentNode.childNodes;
        var num = 0;
        for (var i=0; i<children.length; i++) {
            if (children[i]==node) return num;
            if (children[i].nodeType==1) num++;
        }
        return -1;
    }

}