import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['map'];

    static values = {
        entries: String,
    }

    initialize() {

        const entries = JSON.parse(this.entriesValue);


        let fitarr = []
        var fitlng = 0
        var fitlat = 0

        mapboxgl.accessToken = 'pk.eyJ1Ijoic2NyaXB0c2NobWllZCIsImEiOiJjbG9lZXB3N2wwZjRlMndtbGZhZmJpaWltIn0.zQEP3iYXml62bryG9dnPug';



        Array.from(entries).filter((marker) => {
            let mlng = parseFloat(marker.lng).toFixed(5);
            let mlat = parseFloat(marker.lat).toFixed(5);
            fitarr.push([ mlng, mlat ])
            fitlng = fitlng+parseFloat(marker.lng)
            fitlat = fitlat+parseFloat(marker.lat)
        });

        fitlng = (fitlng/entries.length).toFixed(5)
        fitlat = (fitlat/entries.length).toFixed(5)




        var map = new mapboxgl.Map({
            container: this.mapTarget,
            style: "mapbox://styles/scriptschmied/cloegek3g002r01r0ha525bg6",
            zoom:14,
            showZoom: false,
            scrollZoom      : false,
            boxZoom         : false,
            doubleClickZoom : false,
            center: [ fitlng, fitlat ],
            interactive: true
        });



        Array.from(entries).filter((marker) => {



            var el = document.createElement('div');
            var el_inner
            if(marker.link) {
                el_inner = document.createElement('a');
                el_inner.setAttribute("href", marker.link)
            } else {
                el_inner = document.createElement('div');
            }
            el_inner.classList.add("btn");
            el_inner.innerText = marker.title
            el.className = 'marker-map';

            el.appendChild(el_inner)


            new mapboxgl.Marker(el)
                .setLngLat([ marker.lng, marker.lat ])
                .addTo(map);
        });



        if(entries.length>1) {

            console.log(fitarr)
            var coordinates = fitarr

            var bounds = fitarr.reduce(function(bounds, fitarr) {
                return bounds.extend(fitarr);
            }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));



            map.fitBounds(bounds, {padding: 100, maxZoom: 12.5});

        }

        //map.scrollZoom.disable();

        map.addControl(new mapboxgl.NavigationControl());

    }







}