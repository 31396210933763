import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['table','measure'];

    static values = {
        active: Boolean,
    }

    initialize() {

       this.resize()

    }

    resize() {
        const offsetLeft = this.offset(this.measureTarget).left
        let trs = this.tableTarget.querySelectorAll("tr")
        trs.forEach(function(tr) {
            let td = tr.querySelector(".first-el")
            td.style.paddingLeft = offsetLeft+"px"
        });
    }

    offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

}