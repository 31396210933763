import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['images'];

    static values = {
        index: Number,
        offset: Number
    }

    initialize() {
        this.numberValue = 0;

        const all = this.imagesTarget.querySelectorAll("img")
        if(all.length>0) {

            setTimeout(() => {
                setInterval(() => {
                    this.next()
                }, 4000)
            }, 200 * this.offsetValue)

        }

    }


    next() {
        const all = this.imagesTarget.querySelectorAll("img")

        const cur_element = all[this.numberValue]

        this.numberValue++
        if(this.numberValue==all.length) {
            this.numberValue=0
        }

        const next_element = all[this.numberValue]

        if(next_element.classList.contains("hidden")) {
            next_element.classList.remove("hidden")
            setTimeout(() => {
                cur_element.classList.add("!opacity-0")
                next_element.classList.remove("!opacity-0")
            },10)

        } else {
            cur_element.classList.add("!opacity-0")
            next_element.classList.remove("!opacity-0")
        }


    }



}