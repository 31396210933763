import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['map'];

    static values = {
        entries: String,
        displayas: String
    }

    initialize() {
        ym.ready(() => {

            const entries = JSON.parse(this.mapTarget.getAttribute("data-maps-entries-value"));

            let fitarr = []
            var fitlng = 0
            var fitlat = 0


            Array.from(entries).filter((marker) => {
                let mlng = parseFloat(marker.lng).toFixed(5);
                let mlat = parseFloat(marker.lat).toFixed(5);
                fitarr.push([mlat, mlng])
                fitlng = fitlng + parseFloat(marker.lng)
                fitlat = fitlat + parseFloat(marker.lat)
            });

            fitlng = (fitlng / entries.length).toFixed(5)
            fitlat = (fitlat / entries.length).toFixed(5)

            var map = ym.map(this.mapTarget.querySelector(".mapcontainer"), {
                center: ym.latLng(fitlat, fitlng),
                zoom: 14,
                style: 'greyscale',
                touchZoom: false,
                scrollWheelZoom: false,
                doubleClickZoom: false,
                boxZoom: false,
                zoomControl: false

            });

            let displayas = this.displayasValue

            var markers = ym.markerClusterGroup({
                disableClusteringAtZoom: 12,
                showCoverageOnHover: false,
                iconCreateFunction: function(cluster) {
                    if(displayas=="labels") {
                        var clusticon = "<div class='btn-outer'>";
                        cluster.getAllChildMarkers().forEach((cluster) => {
                            const icon = cluster.getIcon();
                            clusticon = clusticon + icon.options.html
                        });
                        clusticon = clusticon + "</div>"
                        return L.divIcon({html: clusticon});
                    } else {
                        var clusinner = ""
                        cluster.getAllChildMarkers().forEach((cluster) => {
                            const icon = cluster.getIcon();
                            clusinner = icon.options.html
                        });
                        var clusticon = "<div class='btn-outer'>" + clusinner + "</div>"
                        return L.divIcon({html: clusticon});
                    }
                }
            });

            Array.from(entries).filter((marker) => {

                if(this.displayasValue=="labels") {
                    var el = document.createElement('div');
                    var el_inner = document.createElement('div');
                    var el_inner_inner

                    if (marker.link) {
                        el_inner_inner = document.createElement('a');
                        el_inner_inner.setAttribute("href", marker.link)
                    } else {
                        el_inner_inner = document.createElement('div');
                    }
                    el_inner.appendChild(el_inner_inner)
                    el_inner.classList.add("btn");
                    el_inner.classList.add("btn-map");
                    el_inner_inner.innerText = marker.title
                    el.className = 'marker-map';

                    el.appendChild(el_inner)
                    var mapicon = ym.divIcon({className: 'marker-map', html: el.innerHTML});

                    var marker = ym.marker([marker.lat, marker.lng], {icon: mapicon});
                    markers.addLayer(marker)

                } else {
                    var el = document.createElement('div');
                    var el_inner = document.createElement('div');
                    var el_inner_inner

                    if (marker.link) {
                        el_inner_inner = document.createElement('a');
                        el_inner_inner.setAttribute("href", marker.link)
                    } else {
                        el_inner_inner = document.createElement('div');
                    }

                    el_inner.appendChild(el_inner_inner)
                    el_inner.classList.add("logo-map");
                    var el_inner_inner_logo = document.createElement('img');
                    el_inner_inner_logo.setAttribute("src", "/assets/images/logo-badge.svg")
                    el_inner_inner.appendChild(el_inner_inner_logo)
                    el.className = 'marker-map';

                    el.appendChild(el_inner)
                    var mapicon = ym.divIcon({className: 'marker-map', html: el.innerHTML});

                    var marker = ym.marker([marker.lat, marker.lng], {icon: mapicon});
                    markers.addLayer(marker)

                }

            });

            map.addLayer(markers);

            var control = new ym.modules.provider.Control.Zoom();
            control.setPosition("bottomright")
            control.addTo(map);

            map.fitBounds(markers.getBounds());
            map.zoomOut(1);
            if(map.getZoom()>14) {
                map.setZoom(14)
                map.zoomOut(1);
            }
        });

    }



}