import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import AOS from "aos";

export default class extends Controller {

    static targets = ["container","outerscroller"];

    static values = {

    }

    initialize() {

        this.resize()

    }

    resize() {

        let window_width = window.innerWidth;
        this.containerTarget.style.width = (document.documentElement.clientWidth-this.containerTarget.getBoundingClientRect().left) + "px"
        this.outerscrollerTarget.style.height = this.containerTarget.querySelector(".scroller-wrapper").offsetHeight + "px"

        setTimeout(() => {
            AOS.init();
        }, 50);
    }



    indexInParent(node) {
        var children = node.parentNode.childNodes;
        var num = 0;
        for (var i=0; i<children.length; i++) {
            if (children[i]==node) return num;
            if (children[i].nodeType==1) num++;
        }
        return -1;
    }

}