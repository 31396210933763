import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['img', 'measure'];

    static values = {
        active: Boolean,
        ausrichtung: String,
    }

    initialize() {



        this.resize()

    }

    resize() {

        let window_width = window.innerWidth;

        if(window_width<1024) {
            this.imgTarget.style.width = null
            this.imgTarget.style.height = null
        } else {

            if(this.ausrichtungValue=="links") {
                let img_left = this.imgTarget.getBoundingClientRect().right
                this.imgTarget.style.width = img_left+"px"
                this.imgTarget.style.height = (this.measureTarget.offsetHeight)+"px"
            }

            if(this.ausrichtungValue=="rechts") {
                let img_left = this.imgTarget.getBoundingClientRect().left
                let nimg_width = window_width - img_left
                this.imgTarget.style.width = nimg_width+"px"
                this.imgTarget.style.height = (this.measureTarget.offsetHeight)+"px"
            }

            if(this.ausrichtungValue=="member") {
                let img_left = this.imgTarget.getBoundingClientRect().left
                let nimg_width = window_width - img_left
                this.imgTarget.style.width = nimg_width+"px"
            }

            if(this.ausrichtungValue=="uebersicht") {
                let img_left = this.imgTarget.getBoundingClientRect().right
                this.imgTarget.style.width = img_left+"px"
                this.imgTarget.style.height = (this.measureTarget.offsetHeight)+"px"
            }




        }



    }





}