import { Controller } from "@hotwired/stimulus"
import Flickity from 'flickity'

export default class extends Controller {

    static targets = ['loadmore', 'spinner', 'gridelements'];

    static values = {
        active: Boolean,
        kategorie: Number
    }

    connect() {


    }

    loadAjax() {

        let count_all = this.loadmoreTarget.getAttribute("data-all");
        let offset = this.loadmoreTarget.getAttribute("data-offset");
        let lang = this.loadmoreTarget.getAttribute("data-language");

        if(lang=="en-US") {
            lang = "/en"
        } else {
            lang = ""
        }

        offset++;
        this.loadmoreTarget.setAttribute("data-offset", offset);

        const url = lang+this.loadmoreTarget.getAttribute("data-url")+"?offset="+offset+"&kategorie="+this.kategorieValue;

        this.spinnerTarget.classList.remove("hidden")

        fetch(url).then((response) => {
            response.text().then((text) => {
                let el = document.createElement( 'html' );
                el.innerHTML=text;

                document.body.style.top = `-${window.scrollY}px`
                document.body.style.position = 'fixed'
                document.body.style.overflowY = 'scroll'
                document.body.style.width = '100%'

                if((1+offset)*9<count_all) {
                    this.gridelementsTarget.innerHTML += text;
                } else {
                    this.gridelementsTarget.innerHTML += text;
                    this.loadmoreTarget.parentNode.style.display='none';
                }

                let lazys = document.querySelectorAll(".lazy-start");
                lazys.forEach(function(entry) {
                    entry.classList.add("lazyload")
                });


                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                document.body.style.overflowY = ''
                document.body.style.width = ''

                this.spinnerTarget.classList.add("hidden")

                window.scrollTo(0, parseInt(scrollY || '0') * -1);

            });
        });




    }

}