// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        //console.log("HMR")
    });
}
import '../css/main.css'

import './controllers'

import 'lazysizes';
import marquee from "vanilla-marquee";

import AOS from 'aos';
import 'aos/dist/aos.css';

import BScroll from '@better-scroll/core'
import ScrollBar from '@better-scroll/scroll-bar'
BScroll.use(ScrollBar);

import { easepick } from '@easepick/bundle';
import { RangePlugin } from '@easepick/range-plugin';
import { LockPlugin } from '@easepick/lock-plugin';
import { TimePlugin } from '@easepick/time-plugin';
import { AmpPlugin } from '@easepick/amp-plugin';
import '@easepick/bundle/dist/index.css'


let scrollerElements = document.querySelectorAll(".scroller-wrapper");
if(scrollerElements.length>0) {

    scrollerElements.forEach(function (el, index, array) {
        new BScroll(el, {
            scrollX: true,
            wheel: true,
            bounceTime: 400,
            swipeBounceTime: 250,
            click: true,
            tap: true,
            scrollbar: {
                fade: false,
                interactive: true
            }
        })
    });

}

document.addEventListener("DOMContentLoaded", function(){

    window.addEventListener('resize', function () {
        resize();
    });
    resize();


    function resize() {

    }

    let lazys = document.querySelectorAll(".lazy-start");
    lazys.forEach(function(entry) {
        entry.classList.add("lazyload")
    });



    const form = document.querySelector('form[data-freeform]');
    if(form) {

        form.addEventListener('freeform-ajax-success', function (event) {
            init_freeform();
        });
        form.addEventListener('freeform-ajax-after-submit', function (event) {

            let success_message = document.querySelector(".freeform-form-success").cloneNode(true);
            form.innerHTML = success_message.innerHTML
        });
    }

    init_freeform();

    AOS.init();

    // Job Bewerbungsformular Pre Select

    if(document.querySelectorAll("#form-input-jobbezeichnung").length>0) {
        var jobValue = getParameterByName('job');
        document.querySelector("#form-input-jobbezeichnung").value = jobValue
    }

    // Top Marquee

    const screenWidth = window.innerWidth;

    if(document.querySelectorAll("#top-marquee").length>0) {

        let marqueeDuration = 13000;

        if(screenWidth > 1200) {
            marqueeDuration = 24000;
        }

        if(screenWidth > 1600) {
            marqueeDuration = 32000;
        }

        new marquee( document.querySelector("#top-marquee"), {
            //duration in milliseconds of the marquee
            duration: marqueeDuration,
            //gap in pixels between the tickers
            gap: 0,
            //time in milliseconds before the marquee will start animating
            delayBeforeStart: 0,
            //'left' or 'right'
            direction: 'left',
            //true or false - should the marquee be duplicated to show an effect of continues flow
            duplicated: true,
            startVisible: true,
            pauseOnHover: true
        });

        setTimeout(() => {
            document.querySelector("#top-marquee").classList.remove("opacity-0")
        }, 50)
    }

});


document.addEventListener('lazyloaded', function(e) {

    if(e.target.classList.contains("headerimage")) {
        e.target.closest(".pageheaderouter").classList.add("headerloaded")
    }

});

if(document.querySelector(".headervideo")) {
    document.querySelector(".headervideo").addEventListener('play', function (e) {

        e.target.closest(".pageheaderouter").classList.add("headerloaded")

    });
}

function init_freeform() {
    const easypickForms = document.querySelectorAll("[data-easypick]");
    easypickForms.forEach(function(easypick) {
        const picker = new easepick.create({
            element: easypick,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            lang: "de-DE",
            format: "DD MMMM YYYY, HH:mm",
            inline: false,
            LockPlugin: {
                minDate: "2023-12-14T23:00:00.000Z",
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                LockPlugin,
                TimePlugin
            ]
        })
    });

    const easypickFormsEN = document.querySelectorAll("[data-easypick-en]");
    easypickFormsEN.forEach(function(easypick) {
        const picker = new easepick.create({
            element: easypick,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            lang: "en-EN",
            format: "DD MMMM YYYY, HH:mm",
            inline: false,
            LockPlugin: {
                minDate: "2023-12-14T23:00:00.000Z",
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                LockPlugin,
                TimePlugin
            ]
        })
    });

    const easypickFormsSimple = document.querySelectorAll("[data-easypick-simple]");
    easypickFormsSimple.forEach(function(easypick) {
        const picker = new easepick.create({
            element: easypick,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            lang: "de-DE",
            format: "DD MMMM YYYY",
            inline: false,
            LockPlugin: {
                minDate: "2023-12-14T23:00:00.000Z",
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                LockPlugin
            ]
        })
    });

    const easypickFormsSimpleEN = document.querySelectorAll("[data-easypick-simple-en]");
    easypickFormsSimpleEN.forEach(function(easypick) {
        const picker = new easepick.create({
            element: easypick,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            lang: "en-EN",
            format: "DD MMMM YYYY",
            inline: false,
            LockPlugin: {
                minDate: "2023-12-14T23:00:00.000Z",
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                LockPlugin
            ]
        })
    });

    const easypickFormsGeburtstag = document.querySelectorAll("[data-easypick-geburtstag]");
    easypickFormsGeburtstag.forEach(function(easypick) {
        const picker = new easepick.create({
            element: easypick,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            AmpPlugin: {
                dropdown: {
                    months: true,
                    years: true,
                    minYear: 1900
                }
            },
            lang: "de-DE",
            format: "DD MMMM YYYY",
            inline: false,
            LockPlugin: {
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                LockPlugin,
                AmpPlugin
            ]
        })
    });

    const easypickFormsGeburtstagEN = document.querySelectorAll("[data-easypick-geburtstag-en]");
    easypickFormsGeburtstagEN.forEach(function(easypick) {
        const picker = new easepick.create({
            element: easypick,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            AmpPlugin: {
                dropdown: {
                    months: true,
                    years: true,
                    minYear: 1900
                }
            },
            lang: "en-EN",
            format: "DD MMMM YYYY",
            inline: false,
            LockPlugin: {
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                LockPlugin,
                AmpPlugin
            ]
        })
    });

    const easypickFormsRange = document.querySelectorAll("[data-easypick-range]");
    easypickFormsRange.forEach(function(easypick) {
        const picker = new easepick.create({
            element: easypick,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            lang: "de-DE",
            format: "DD. MMMM YYYY",
            inline: false,
            RangePlugin: {
                locale: {
                    one: "Tag",
                    other: "Tage"
                }
            },
            LockPlugin: {
                minDate: "2023-12-14T23:00:00.000Z",
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                RangePlugin,
                LockPlugin
            ]
        })
    });

    const easypickFormsRangeEn = document.querySelectorAll("[data-easypick-range-en]");
    easypickFormsRangeEn.forEach(function(easypick) {
        const picker = new easepick.create({
            element: easypick,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            lang: "en-EN",
            format: "MMMM DD YYYY",
            inline: false,
            RangePlugin: {
                locale: {
                    one: "Tag",
                    other: "Tage"
                }
            },
            LockPlugin: {
                minDate: "2023-12-14T23:00:00.000Z",
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                RangePlugin,
                LockPlugin
            ]
        })
    });

    const easypickFormsRangeEs = document.querySelectorAll("[data-easypick-range-es]");
    easypickFormsRangeEs.forEach(function(easypick) {
        const picker = new easepick.create({
            element: easypick,
            css: ['/assets/easypick/index.css'],
            zIndex: 10,
            lang: "es-ES",
            format: "DD. MMMM YYYY",
            inline: false,
            RangePlugin: {
                locale: {
                    one: "día",
                    other: "días"
                }
            },
            LockPlugin: {
                minDate: "2023-12-14T23:00:00.000Z",
                selectForward: true,
                presets: false,
                inseparable: true
            },
            plugins: [
                RangePlugin,
                LockPlugin
            ]
        })
    });
}


function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

