import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    static targets = ['masonry', 'outer', 'container'];

    static values = {
        active: Boolean,
        count: Number,
        id: Number,
        maxKgv: Number
    }

    initialize() {
        this.init()
        this.resize();
    }

    init() {

        const widths = [42.53,47.3,30.5,55.9]
        const between = [65.275,26.04,25,39.0];
        const tops = [5.2,42.36,0,21.875];
        let cur_pos = -between[0]
        let clones = []

        this.masonryTarget.classList.add("impression-"+this.idValue)

        let window_width = window.innerWidth;
        let images = this.masonryTarget.querySelectorAll(".item")
        const images_length = images.length

        images.forEach((image) => {
            clones.push(image.cloneNode(true))
        });

        var max_images = this.kgv(images_length,4);

        if(max_images<8) {
            max_images = 8;
        }

        var max_images_kgv = max_images
        this.maxKgvValue = max_images_kgv

        var max_images_safe = max_images + 4

        max_images = max_images-images_length

        for(var i = 0; i<max_images_safe; i++) {
            this.masonryTarget.appendChild(clones[i%images_length].cloneNode(true))
        }


        images = this.masonryTarget.querySelectorAll(".item")

        let c = 0
        let c_a = 0

        let animation_vw = 0;

        images.forEach((image) => {


            cur_pos = cur_pos + between[c%4];

            if(c<max_images_kgv) {
                animation_vw = cur_pos
                c_a++
            }

            image.style.transform = "translate("+cur_pos+"vw, "+tops[c%4]+"vw)"

            var img = image.querySelector("img")
            img.setAttribute("src", img.getAttribute("data-src"))
            img.setAttribute("srcset", img.getAttribute("data-srcset"))
            img.classList.add("lazyloaded")

            c++;

        });

        animation_vw = animation_vw + between[c_a%4]
        const animation_ratio = 20/155.315
        const animation_timing = (animation_vw*animation_ratio).toFixed(3)


        var sheet = document.createElement('style')
        sheet.setAttribute("id", "style"+this.idValue)
        sheet.innerHTML = "@keyframes infiniteScroll"+this.idValue+" { from {transform: translateX(0) } to {transform: translateX( -"+animation_vw+"vw );} }\n\n"
        + ".impression-"+this.idValue+" { animation-duration: "+animation_timing+"s; animation-name: infiniteScroll"+this.idValue+"; }";
        document.body.appendChild(sheet);

    }

    resize() {

        this.outerTarget.style.width = document.documentElement.clientWidth+"px"
        this.containerTarget.style.width = (document.documentElement.clientWidth - this.containerTarget.getBoundingClientRect().left) + "px"

        this.masonryTarget.classList.remove("impression-"+this.idValue)
        var oldsheet = document.getElementById("style"+this.idValue)
        oldsheet.remove()

        var sheet = document.createElement('style')
        sheet.innerHTML = ""

        this.idValue = Math.floor(Date.now() / 1000)

        this.masonryTarget.classList.add("impression-"+this.idValue)

        const images = this.masonryTarget.querySelectorAll(".item")

        const widths = [42.53,47.3,30.5,55.9]
        const between = [65.275,26.04,25,39.0];
        const tops = [5.2,42.36,0,21.875];
        let cur_pos = -between[0]

        let c = 0
        let c_a = 0

        let animation_vw = 0;

        images.forEach((image) => {


            cur_pos = cur_pos + between[c%4];

            if(c<this.maxKgvValue) {
                animation_vw = cur_pos
                c_a++
            }

            image.style.transform = "translate("+cur_pos+"vw, "+tops[c%4]+"vw)"

            c++;

        });

        animation_vw = animation_vw + between[c_a%4]
        const animation_ratio = 20/155.315
        const animation_timing = (animation_vw*animation_ratio).toFixed(3)

        sheet.setAttribute("id", "style"+this.idValue)
        sheet.innerHTML = "@keyframes infiniteScroll"+this.idValue+" { from {transform: translateX(0) } to {transform: translateX( -"+animation_vw+"vw );} }\n\n"
            + ".impression-"+this.idValue+" { animation-duration: "+animation_timing+"s; animation-name: infiniteScroll"+this.idValue+"; }";
        document.body.appendChild(sheet);
    }

    ggt(a, b) {
        while(true)
        {
            a = a % b;
            if(a == 0) return b;
            b = b % a;
            if(b == 0) return a;
        }
    }

    kgv(a, b) {
        if(a == 0 || b == 0) return 1;
        return Math.abs(a*b)/this.ggt(a,b);
    }




}