import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = [];

    static values = {
        active: Boolean,
    }

    initialize() {



    }

    anchor() {

        const anchor = this.getAnchor()
        if(anchor!=null) {

            if(document.querySelector("#"+anchor)) {

                document.querySelector("html").classList.add("!scroll-auto");
                setTimeout(() => {
                    document.querySelector("#" + anchor).scrollIntoView();
                }, 10)
                setTimeout(() => {
                    document.querySelector("html").classList.remove("!scroll-auto");
                }, 1000)

            }

        }

    }

    getAnchor() {
        var currentUrl = document.URL,
            urlParts   = currentUrl.split('#');

        return (urlParts.length > 1) ? urlParts[1] : null;
    }


}