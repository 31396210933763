import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    static targets = ["amount","amountdisplaysingular","amountdisplayplural"];

    static values = {

    }

    initialize() {

    }

    up() {
        let d = parseInt(this.amountTarget.innerHTML) + 1;
        if(d>=10) {
            d=10;
        }
        this.amountTarget.innerHTML = d

        const trigger_updatebooking_amount = new CustomEvent("trigger-updatebookingamount");
        window.dispatchEvent(trigger_updatebooking_amount);
        this.updatedisplay();
    }

    down() {
        let d = parseInt(this.amountTarget.innerHTML) - 1;
        if(d<=0) {
            d=1;
        }
        this.amountTarget.innerHTML = d

        const trigger_updatebooking_amount = new CustomEvent("trigger-updatebookingamount");
        window.dispatchEvent(trigger_updatebooking_amount);
        this.updatedisplay();
    }

    updatedisplay() {
        let d = parseInt(this.amountTarget.innerHTML)
        if (d > 1) {
            this.amountdisplaysingularTarget.classList.add("hidden")
            this.amountdisplaypluralTarget.classList.remove("hidden")
        } else {
            this.amountdisplaysingularTarget.classList.remove("hidden")
            this.amountdisplaypluralTarget.classList.add("hidden")
        }
        this.amountTarget.closest(".booking-bar").setAttribute("data-amount", d)
    }

}