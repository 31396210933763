import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['zimmer', 'nav'];

    static values = {
        active: Boolean,
    }

    initialize() {

        if(this.zimmerTarget.querySelectorAll(".zimmer").length) {
            const first = this.zimmerTarget.querySelectorAll(".zimmer")[0]
            first.classList.remove("hidden")

            const firstNav = this.navTarget.querySelectorAll(".nav-entry")[0]
            firstNav.classList.add("active")
        }


    }

    switch(event) {
        const index = this.indexInParent(event.target);
        const zimmer = this.zimmerTarget.querySelectorAll(".zimmer")
        zimmer.forEach((z) => {
            z.classList.add("hidden")
        });
        zimmer[index].classList.remove("hidden")

        const active = event.target.parentNode.querySelectorAll(".active");
        active.forEach((a) => {
            a.classList.remove("active")
        });
        event.target.classList.add("active")

        const trigger = new CustomEvent("trigger-breakoutresize");
        window.dispatchEvent(trigger);
    }


    indexInParent(node) {
        var children = node.parentNode.childNodes;
        var num = 0;
        for (var i=0; i<children.length; i++) {
            if (children[i]==node) return num;
            if (children[i].nodeType==1) num++;
        }
        return -1;
    }


}