import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['images', 'outer', 'img1','img2','img3'];

    static values = {
        active: Boolean,
    }

    initialize() {

        this.resize()

    }

    resize() {

        let window_width = window.innerWidth;


        if(window_width<1024) {
            this.img1Target.style.width = null
            this.img2Target.style.width = null
            this.img3Target.style.width = null
        } else {
            let img1_left = this.img1Target.getBoundingClientRect().left
            let img2_left = this.img2Target.getBoundingClientRect().left
            let img3_left = this.img3Target.getBoundingClientRect().left

            let nimg1_width = window_width - img1_left
            let img1_outer_width = this.img1Target.parentNode.offsetWidth

            let scale = nimg1_width/img1_outer_width

            if(scale > 1.7 ) scale = 1.7

            this.img1Target.style.transform = "scale("+scale+")"
            this.img2Target.style.transform = "scale("+scale+")"
            this.img3Target.style.transform = "scale("+scale+")"



            let img3_outer_height = this.img3Target.parentNode.offsetHeight
            let nimg3_height = img3_outer_height*scale

            this.outerTarget.style.paddingBottom = (nimg3_height - img3_outer_height) + "px"


        }


        const imagesTop = this.offset(this.imagesTarget).top
        const image2Top = this.offset(this.img2Target).top

        if(image2Top<imagesTop) {
            const dif = imagesTop-image2Top
            this.imagesTarget.style.marginTop = dif + "px"
        } else {
            this.imagesTarget.style.marginTop = 0 + "px"
        }


    }


    offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }


}