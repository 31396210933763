import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['images'];

    static values = {
        index: Number,
    }

    initialize() {
        this.numberValue = 0;
    }


    next() {
        const all = this.imagesTarget.querySelectorAll("img")

        const cur_element = all[this.numberValue]

        this.numberValue++
        if(this.numberValue==all.length) {
            this.numberValue=0
        }

        const next_element = all[this.numberValue]

        cur_element.classList.add("!opacity-0")
        next_element.classList.remove("!opacity-0")

    }


    previous() {
        const all = this.imagesTarget.querySelectorAll("img")

        const cur_element = all[this.numberValue]

        this.numberValue--
        if(this.numberValue<0) {
            this.numberValue=all.length-1
        }

        const next_element = all[this.numberValue]

        cur_element.classList.add("!opacity-0")
        next_element.classList.remove("!opacity-0")

    }



}